import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({description, keywords, title, url, image}) => {


    const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                title
                description
                siteUrl
                lang
              }
            }
          }
        `
      )

      const metaDescription = description || site.siteMetadata.description
      const metaTitle = title || site.siteMetadata.title
      const metaUrl = url || site.siteMetadata.url
      const metaKeywords = keywords.length > 0 ? keywords : ["Rakennuspalvelut", "Maanrakennus", "Saneeraus", "Uudisrakentaminen"]
      const metaImage = image
      const lang = "fi"


      return (
        <Helmet
          htmlAttributes={{
            lang
          }}
          title={title}
     
          meta={[
            {
              name: `description`,
              content: metaDescription,
            },
            {
                name: `keywords`,
                content: metaKeywords,
              },
            {
              property: `og:title`,
              content: metaTitle,
            },
            {
              property: `og:description`,
              content: metaDescription,
            },
            {
              property: `og:type`,
              content: `website`,
              
            },
            {
                property: `og:image`,
                content: metaImage,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
            {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: `Ylitalon rakennuspalvelut Oy`,
              },
              {
                name: `twitter:title`,
                content: metaTitle,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:image`,
                content: metaImage,
              },
              {
                name: `twitter:url`,
                content: metaUrl,
              },
          ].concat(
              metaKeywords && metaKeywords.length > 0 ? {
                  name: `keywords`,
                  content: metaKeywords.join(`, `),
              } : ["test", "test2"]
          )}
        />
      )

    
}

export default Seo